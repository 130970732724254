<template>
  <div class="partner_info">
    <div class="main_title">合作伙伴</div>
    <div class="sub_title">cooperative partner</div>
    <div class="img_box">
      <div class="img_content">
        <img v-for="(item,index) in partnerList" :src="item" alt="" :key="index" class="img_item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerInfo',
    components: {
    },
  data() {
    return {
      partnerList: [
        require('@/assets/images/strength-info/partner/pic_1.png'),
        require('@/assets/images/strength-info/partner/pic_2.png'),
        require('@/assets/images/strength-info/partner/pic_3.png'),
        require('@/assets/images/strength-info/partner/pic_4.png'),
        require('@/assets/images/strength-info/partner/pic_5.png'),
        require('@/assets/images/strength-info/partner/pic_6.png'),
        require('@/assets/images/strength-info/partner/pic_6.png'),
        require('@/assets/images/strength-info/partner/pic_8.png'),
        require('@/assets/images/strength-info/partner/pic_9.png'),
        require('@/assets/images/strength-info/partner/pic_10.png'),
        require('@/assets/images/strength-info/partner/pic_11.png'),
        require('@/assets/images/strength-info/partner/pic_12.png'),
        require('@/assets/images/strength-info/partner/pic_13.png'),
        require('@/assets/images/strength-info/partner/pic_14.png'),
        require('@/assets/images/strength-info/partner/pic_15.png'),
        require('@/assets/images/strength-info/partner/pic_16.png'),
        require('@/assets/images/strength-info/partner/pic_17.png'),
        require('@/assets/images/strength-info/partner/pic_18.png'),
      ]
    }
  },
  computed: {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.partner_info {
  margin-top: 0.4rem;
}
.main_title {
  text-align: center;
  font-weight: bold;
  font-size: 0.4rem;
}
.sub_title {
  text-align: center;
  font-size: 0.28rem;
  color: #757679;
  padding-bottom: 0.1rem;
}
.img_box {
  padding: 0.4rem 0.3rem;
}
.img_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.img_item {
  width: 2.1rem;
  height: 100%;
  // width: 100%;
  // margin-left: 0.1rem;
  margin-bottom: 0.2rem;
  box-shadow: 0 0 0.2rem rgba(159, 162, 168, 0.5);
}
</style>
