import {
  instance,
  instanceWithToken,
  instanceBlob,
  instanceOss
} from './httpConfig'

const formData = function (obj) {
  let data = new FormData()
  for (let val in obj) {
    obj[val] !== undefined && data.append(val, obj[val])
  }
  return data
}

const get = (url, params) => {
  return instance.get(url, { params })
}

const getWithToken = (url, params) => {
  return instanceWithToken.get(url, { params })
}

const post = (url, data) => {
  return instance.post(url, data)
}

const postWithToken = (url, data) => {
  return instanceWithToken.post(url, data)
}

const postForm = (url, data) => {
  return instance.post(url, formData(data))
}

const postFormWithToken = (url, data) => {
  return instanceWithToken.post(url, formData(data))
}
const putWithToken = (url, data) => {
  return instanceWithToken.put(url, data)
}

/* 下载oss文件 */
const ossDownload = (url, params) => {
  return instanceOss.get(url, { params })
}

/* 下载流文件post */
const postDownload = (url, data) => {
  return instanceBlob.post(url, formData(data))
}

/* 下载流文件get请求 */
const getDownload = (url, params) => {
  return instanceBlob.get(url, { params })
}

export {
  get,
  getWithToken,
  post,
  postWithToken,
  postForm,
  postFormWithToken,
  ossDownload,
  getDownload,
  postDownload,
  putWithToken
}
