import axios from 'axios'
import router from '../../router'
import store from '@/store'

let baseUrl = 'https://bussinessdevapi.topsmh.com' // 开发
baseUrl = 'http://bussinessdevapi.test.topsmh.com' // 测试
if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://bussinessdevapi.test.topsmh.com'
} else if (process.env.NODE_ENV === 'production') {
  baseUrl = 'https://myapi.topsmh.com'
}

/* http请求配置 */
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 3000 * 60
})

const instanceWithToken = axios.create({
  baseURL: baseUrl,
  timeout: 3000 * 60
})

/* 下载流文件blob配置 */
const instanceBlob = axios.create({
  baseURL: baseUrl,
  timeout: 3000 * 60,
  responseType: 'blob'
})

/* 下载OSS文件 */
const instanceOss = axios.create({
  baseURL: 'https://smh-devfile.oss-cn-shenzhen.aliyuncs.com/',
  timeout: 3000 * 60,
  responseType: 'blob'
})

/* 请求拦截 */
const axiosRequest = axios => {
  axios.interceptors.request.use(
    config => {
      let token = window.localStorage.getItem('token')
      if (token) {
        config.headers.Authorization = token
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
}

/* 响应拦截 */
const axiosResponse = axios => {
  axios.interceptors.response.use(
    res => {
      if (res.data.code == 401) {
        if (res.config.url != '/message/queryUnread') {
          router.push('/home')
        }
      } else if (res.data.code != 201 && res.data.code != 200) {

      }
      return res.data
    },
    error => {
      // Message.error('网络故障,请稍后再刷新访问')
      return Promise.reject(error)
    }
  )
}

/* 配置axios拦截 */
axiosRequest(instanceWithToken)
axiosRequest(instanceBlob)

axiosResponse(instance)
axiosResponse(instanceWithToken)

export {
  baseUrl,
  instance,
  instanceWithToken,
  instanceBlob,
  instanceOss
}
