<template>
  <div class="news">
    <div class="main_title">跨境资讯</div>
    <div class="sub_title">Cross border information</div>
    <!-- 新闻菜单 -->
    <van-tabs v-model="activeTab" @change="tabChange" color="#4e5d9a" title-active-color="#4e5d9a" title-inactive-color="#3A3A3B" class="news_menu">
      <van-tab v-for="(item,index) in tabList" :title="item.essaySignName" :name="index" :key="item.essaySignName"></van-tab>
    </van-tabs>
    <!-- 轮播图 -->
    <van-swipe ref="swipe" @change="swipeChange" :autoplay="3000" class="news_swipe">
      <van-swipe-item v-for="(item, index) in newsList" :key="index">
        <img :src="item.coverImage" class="swipe_img" />
      </van-swipe-item>
    </van-swipe>
    <!-- 轮播图新闻标题 -->
    <div v-if="newsList.length > 0">
      <div class="news_title">
        <div class="title_content">
          <div class="title_item">{{ newsList[curSwipe].title }}</div>
          <div class="title_date">{{ newsList[curSwipe].createTime.replace(/-/g, '/') }}</div>
        </div>
        <div @click="toNewsList" class="img_content">
          <img src="@/assets/images/arrow_right.png" class="details_icon" />
        </div>
      </div>
      <!-- 新闻列表 -->
      <div v-for="item in newsList" :key="item.title" class="news_content">
        <div class="news_top">
          <div class="date_box">
            <div class="content_month">{{item.createTime.substring(item.createTime.length -2)}}</div>
            <div class="content_date">{{item.createTime.substring(0,7).replace(/-/g, '/')}}</div>
          </div>
          <div class="content_title ellipsis2">{{item.title}}</div>
        </div>
        <div class="news_details ellipsis2" v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tab, Tabs, Swipe, SwipeItem, Lazyload, Collapse, CollapseItem, } from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Lazyload);
export default {
  name: 'PartnerInfo',
    components: {
    },
  data() {
    return {
      activeTab: 0,
      curSwipe: 0,
      tabList: [],
      images: [],
      newsList: [],
    }
  },
  computed: {},
  created() {
    this.getNewsList()
    this.getTabsList()
  },
  methods: {
    // tab菜单切换
    tabChange(index) {
      this.getNewsList(this.tabList[index].bid)
    },

    swipeChange(val) {
      this.curSwipe = val
    },

    // 获取文章标签列表
    getTabsList() {
      this.$get('/essay/management/essaySignList',).then(res => {
        if (res.code === 200) {
          this.tabList = res.data.filter(item => item.location === 2);
          this.tabList.length = 3
          this.tabList.unshift({ bid: '', essaySignName: '全部'})
        }
      })
    },

    // 文章列表
    getNewsList(bid = '') {
      const url = `/essay/management/essayList?&pageNum=1&pageSize=3&bid=${bid}`;
      this.$get(url).then((res) => {
        if (res.code === 200) {
          this.newsList = res.data.rows.map((item) => {
            item.coverImage = item.coverImage.split(",")[0];
            item.createTime = item.createTime.substring(0, 10);
            item.content = item.content.replace(/<[^>]+>/g, "");
            return item;
          });
          this.swipeToFirst()
        }
      });
    },

    // 轮播图到第一张
    swipeToFirst() {
      this.$refs['swipe'].swipeTo(0)
    },

    toNewsList() {
      return
      this.$router.push('/newsList')
    },

  },
}
</script>

<style lang="scss" scoped>
.news {
  background-color: white;
  overflow: hidden;
}
.news_menu {
  border-bottom: 0.01rem solid #d9d9d9;
}
.main_title {
  text-align: center;
  font-weight: bold;
  font-size: 0.4rem;
  margin-top: 0.4rem;
}
.sub_title {
  text-align: center;
  font-size: 0.28rem;
  color: #757679;
  margin-bottom: 0.4rem;
}
.news_title {
  display: flex;
  justify-content: space-between;
}
.news_swipe {
  margin-top: 0.1rem;
}
.news_content {
  background-color: #f3f3f3;
  margin: 0.3rem;
  padding: 0.2rem 0.3rem;
}
.swipe_img {
  width: 100%;
  height: 3.4rem;
}
.title_content {
  margin: 0.2rem 0 0 0.3rem;
}
.title_item {
  font-size: 0.3rem;
  font-weight: bold;
  color: #3A3A3B;
  height: 0.8rem;
}
.title_date {
  margin-top: 0.1rem;
  color: #97989C;
  font-size: 0.24rem;
}
.img_content {
  position: relative;
  margin-right: 0.36rem;
  width: 0.38rem;
  min-width: 0.38rem;
}
.details_icon {
  width: 0.38rem;
  height: 0.38rem;
  position: absolute;
  bottom: 0.2rem;
}
.date_box {
  color: #97989C;
  width: 1.16rem;
  min-width: 1.16rem;
  border-right: 0.02rem solid #CCCCCC;
}
.news_top {
  display: flex;
}
.content_month {
  font-weight: bold;
  font-size: 0.44rem;
}
.content_date {
  font-size: 0.22rem;
}
.content_title {
  margin-left: 0.2rem;
  font-weight: bold;
  font-size: 0.3rem;
  color: #3A3A3B;
}
.news_details {
  padding-left: 1.4rem;
  font-size: 0.24rem;
}
</style>
