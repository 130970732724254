<template>
<div>
  <div class="follow_wechat" v-show="isShow">
    <van-icon @click="toClose" name="cross" class="cross_icon" />
    <img src="@/assets/wechat_code.png" class="code_img" />
    <div>
      <div class="content_1">关注神马汇公众号</div>
      <div class="content_2">微信扫一扫，产品动态先知道</div>
    </div>
    <van-button @click="toWechat" round type="primary" class="content_btn">立即关注</van-button>
  </div>

</div>
</template>

<script>
import Vue from 'vue';
import { Icon, Button } from 'vant';

Vue.use(Button);
Vue.use(Icon);
import { jumpWechat } from '@/mixins/jumpWechat.js'

export default {
  name: 'FollowWechat',
  mixins: [ jumpWechat ],
  data() {
    return {
      isShow: true,
    }
  },
  computed: {},
  created() {
    
  },
  methods: {
    toClose() {
      this.isShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
.follow_wechat {
  width: 100%;
  padding: 0.3rem;
  background-color: #d1d7ec;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cross_icon {
  font-size: 0.26rem;
}
.code_img {
  width: 0.84rem;
  margin: 0 0.2rem;
}
.content_1 {
  font-size: 0.28rem;
}
.content_2 {
  font-size: 0.24rem;
}
.content_btn {
  height: 0.5rem;
  font-size: 0.24rem;
  margin-left: 0.3rem;
  white-space: nowrap;
  padding: 0 0.2rem;
}
</style>
