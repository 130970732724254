<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  },
  mounted() {
    // 判断是否移动设备用户
    if (!this._isMobile()) {
      let host = process.env.NODE_ENV === 'development' ?  'https://www-test.topsmh.com' : 'https://www.topsmh.com'
      window.location.replace(host)
    }
  }
}
</script>
<style>
</style>
