<template>
  <div class="strength_info">
    <div class="main_title">为什么选择我们？</div>
    <div class="sub_title">Why choose us？</div>
    <div v-for="item in list" :key="item.title" class="strength_list" :class="'list_item_' + item.afterIcon">
      <img class="list_icon" :src="require(`@/assets/images/strength-info/${item.frontIcon}.png`)" />
      <div class="list_label">
        <div class="list_title">{{item.title}}</div>
        <div class="list_desc">{{item.info}}</div>
      </div>
    </div>
    <div class="main_title">我们的综合实力</div>
    <div class="sub_title">Our comprehensive strength</div>
    <div class="strength_display">
      <div class="carousel_box">
        <div class="pre_arrow">
          <img src="@/assets/images/arrow-left.png" @click="preMove" alt="" class="arrow_icon" />
        </div>
        <div class="content_box">
          <ul ref="ul" class="content_list" style="marginLeft:0rem">
            <li v-for="item in carouselList" :key="item.text" class="content_item">
              <img :src="item.image" alt="" class="content_img" />
            </li>
          </ul>
        </div>
        <div class="next_arrow">
          <img src="@/assets/images/arrow_right.png" alt="" @click="nextMove" class="arrow_icon" />
        </div>
      </div>
      <div class="count_box" ref="count_box">
        <div v-for="(item,index) in numberList" :key="item.label" class="count_content" :class="'number_' + index">
          <div class="number_content">
            <count-to class="count_number" :class="'count_' + index" ref="example" :startVal="0" :endVal="item.number" :duration="2000">{{ item.number }}</count-to>
            <!-- <i class="count_icon">+</i> -->
            <van-icon name="plus" class="count_icon" />
          </div>
          <span class="count_label">{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Icon } from 'vant';

Vue.use(Icon);
import CountTo from "vue-count-to";
export default {
  name: 'StrengthInfo',
  components: {
    CountTo,
  },
  data() {
    return {
      list: Object.freeze([
        { title: '海内外多地设置分公司与办事处', info: '无缝对接、让您省心，省时，省钱', frontIcon: 'choose_icon_1', afterIcon: '01' },
        { title: '团队10年+行业从业经验', info: '拥有资深的跨境出海服务及管理经验', frontIcon: 'choose_icon_2', afterIcon: '02' },
        { title: '7*24小时在线服务', info: '时刻有专业人士为您解答', frontIcon: 'choose_icon_3', afterIcon: '03' },
        { title: '深悉跨境电商的需求', info: '为您提供全面的跨境出海解决方案', frontIcon: 'choose_icon_4', afterIcon: '04' },
        { title: '优质贴心服务', info: '服务客户50000+，经验丰富、效率高 ', frontIcon: 'choose_icon_5', afterIcon: '05' },
      ]),
      carouselList: [
        { image: require("@/assets/images/strength-info/home_credential1.png") },
        { image: require("@/assets/images/strength-info/home_credential2.png") },
        { image: require("@/assets/images/strength-info/home_credential3.png") },
        { image: require("@/assets/images/strength-info/home_credential4.png") },
        { image: require("@/assets/images/strength-info/home_credential5.png") },
      ],
      numberList: [
        {
          number: 50126,
          label: "服务客户",
        },
        {
          number: 15,
          label: "海外办事处",
        },
        {
          number: 4,
          label: "中国分公司",
        },
      ],
    }
  },
  mounted() {
    // 监听元素是否进入 / 移出可视区域
    window.addEventListener("scroll", this.scrollHandle, true);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("scroll", this.scrollHandle, true);
    });
  },
  methods: {
    // 数字滚动
    scrollHandle() {
      const offset = this.$refs.count_box.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;
      // 进入可视区域触发数字滚动
      if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
        this.$refs.example.forEach((item) => item.start());
        window.removeEventListener("scroll", this.scrollHandle, true);
      }
    },

    preMove() {
      // 点击上一张,把最后一张图片挪到第一张
      this.carouselList.unshift(this.carouselList.pop())
    },

    nextMove() {
      // 点击下一张,把第一张图片挪到最后一张
      this.carouselList.push(this.carouselList.shift())
    },
  },
}
</script>

<style lang="scss" scoped>
.strength_info {
  background-color: white;
  overflow: hidden;
}
.main_title {
  text-align: center;
  font-weight: bold;
  font-size: 0.4rem;
  margin-top: 0.4rem;
}
.sub_title {
  text-align: center;
  font-size: 0.28rem;
  color: #757679;
  padding-bottom: 0.1rem;
}
.strength_list {
  display: flex;
  align-items: center;
  margin: 0.3rem;
  padding: 0.2rem 0.16rem;
  border-radius: 0.1rem;
  border: 0.01rem solid white;
  box-shadow: 0 0 0.2rem rgba(159, 162, 168, 0.5);
}
.list_icon {
  height: 1rem;
}
.list_label {
  margin-left: 0.1rem;
}

.list_title {
  font-weight: bold;
  font-size: 0.3rem;
}
.list_desc {
  margin-top: 0.2rem;
  font-size: 0.24rem;
}
.strength_display {
  background: url('../../../assets/images/strength-info/bg_2.png') no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  padding-bottom: 0.6rem;
}
.carousel_box {
  display: flex;
  align-items: center;
  margin: 0.3rem;
  padding: 0.4rem;
  background-color: #fff;
  box-shadow: 0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  border-radius: 0.1rem;
}
.content_box {
  overflow: hidden;
}
.content_list {
  display: flex;
}
.content_item {
  margin-right: 0.46rem;
  width: 2.04rem;
}
.pre_arrow {
  margin-right: 0.3rem;
}
.next_arrow {
  margin-left: 0.3rem;
}
.arrow_icon {
  width: 0.38rem;
}
.content_img {
  border: 0.06rem solid rgba(78, 93, 154, 0.5);
  display: block;
  width: 2.04rem;
  height: 2.54rem;
}
.content_img:hover {
  border-width: 0;
}
.count_box {
  display: flex;
  justify-content: space-between;
  margin-top: 0.6rem;
  padding: 0 0.3rem;
}
.count_content {
  position: relative;
}
.count_number {
  font-size: 0.6rem;
  font-weight: bold;
}
.count_icon {
  width: 0.28rem;
  height: 0.5rem;
  font-size: 0.24rem;
  transform: translateY(-0.4rem);
}
.count_label {
  font-size: 0.24rem;
}
.number_0 {
  width: 2.6rem;
  min-width: 2.6rem;
}
.number_1 {
  min-width: 1.6rem;
}
.number_2 {
  min-width: 1.2rem;
}
.number_0,.number_1 {
  border-right: 0.03rem solid #707070;
  padding-right: 0.4rem;
}
.count_0 {
  width: 2rem;
  display: inline-block;
}
@for $i from 1 through 5 {
  .list_item_0#{$i} {
    background: url('../../../assets/images/strength-info/0#{$i}.png') no-repeat;
    background-size: 0.8rem;
    background-position: calc(100% - 0.2rem) 50%;
  }
}
</style>
