<template>
  <div class="common_header">
    <img src="@/assets/images/logo.png" class="logo_icon" />
    <img  @click="jumpToWechat" src="@/assets/images/user.png" class="user_icon" />
  </div>
</template>

<script>
import { jumpWechat } from '@/mixins/jumpWechat.js'
export default {
  name: 'commonHearder',
  mixins: [ jumpWechat ],
  data() {
    return {

    }
  },
  methods: {
    jumpToWechat() {
      let params = { path: 'pages/login/login' }
      this.toWechat(params)
    },
  }
}
</script>

<style lang="scss" scoped>
.common_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.12rem 0.3rem;
  background-color: white;
}
.logo_icon {
  width: 2rem;
  height: 100%;
}
.user_icon {
  width: 0.5rem;
  height: 100%;
}
</style>
