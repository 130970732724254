<template>
  <div>
    <div @click="toWechat" class="follow_menu">
      <img src="@/assets/images/shop_car.png" class="icon_img" />
      <p class="icon_label">购物车</p>
      <img src="@/assets/images/customer_service.png" class="icon_img" />
      <p class="icon_label">平台客服</p>
    </div>
  </div>
</template>

<script>
import { jumpWechat } from '@/mixins/jumpWechat.js'

export default {
  name: 'FollowMenu',
  mixins: [ jumpWechat ],
  data() {
    return {
      isShow: true,
    }
  },
  computed: {},
  created() {
    
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.follow_menu {
  background-color: white;
  padding: 0.2rem;
  box-shadow: 0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  border-radius: 0.1rem;
  text-align: center;
}
.icon_img {
  width: 0.38rem;
  height: 0.38rem;
}
.icon_label {
  font-size: 0.2rem;
  color: #3A3A3B;
}
</style>
