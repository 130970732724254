<template>
  <div>
    <van-tabs v-model="activeTab" @change="tabChange" color="#4e5d9a" title-active-color="#4e5d9a" title-inactive-color="#3A3A3B" title-class="tab_menu">
      <van-tab v-for="item in tabList" :title="item.label" :name="item.value" :key="item.label"></van-tab>
    </van-tabs>
    <div class="product_content">
      <div class="title_box">
        <div>
          <div class="title_text">{{curProduct.label}}</div>
          <div class="title_msg">{{curProduct.message}}</div>
        </div>
        <img :src="require(`@/assets/images/product-info/${curProduct.icon}.png`)" class="bg_img" />
      </div>
      <div class="product_box">
        <div class="more_title" @click="jumpToWechat">
          <div>查看更多服务商品</div>
          <van-icon name="arrow" />
        </div>
        <van-swipe class="swipe_container" @change="swipeChange" :autoplay="3000" indicator-color="white" :show-indicators="false">
          <van-swipe-item v-for="(item,index) in productList" :key="index">
            <div @click="jumpToWechat" class="swipe_item">
              <product-item :row="item[0]" />
              <product-item :row="item[1]" />
            </div>
          </van-swipe-item>
        </van-swipe>
        <div class="indicator_dots_box">
          <div v-for="(item,index) in productList" :key="index" class="indicator_dots_item" :class="{'active_dots': index == swipeIndex}"></div>
        </div>
        <div class="opt_btns">
          <van-button @click="jumpToWechat" type="primary">立即购买</van-button>
          <van-button @click="toCustomer" type="default">在线咨询</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Icon, Swipe, SwipeItem, Button, Tab, Tabs } from 'vant';

Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
import ProductItem from './ProductItem.vue'
import { tradmarkCountryList } from '@/const/index.js'
import { jumpWechat } from '@/mixins/jumpWechat.js'
export default {
  name: 'ProductInfo',
  mixins: [jumpWechat],
  components: { ProductItem, },
  data() {
    return {
      activeTab: 0,
      tabList: [
        {
          value: 0,
          label: '商标商城',
          message: '海量海内外现成商标，即买即用、无需等待',
          icon: 'icon_1',
          productTypeBid: 1625286683563423,
          list: [],
        },
        {
          value: 1,
          label: 'VAT服务',
          message: '英德法意西/波兰/捷克/荷兰/奥地利/阿联酋/墨西哥',
          icon: 'icon_2',
          productTypeBid: 1625286592155904,
          list: [],
        },
        {
          value: 2,
          label: '知识产权',
          message: '中国/美国/欧洲/日本/英国及全球各地区商标及专利',
          icon: 'icon_1',
          productTypeBid: 1625286609258453,
          list: [],
        },
        {
          value: 3,
          label: 'EPR服务',
          message: '欧代/英代/包装法/电池法/WEEE/纺织品/家具回收/纸回收',
          icon: 'icon_4',
          productTypeBid: 1625286625324555,
          list: [],
        },
        {
          value: 4,
          label: '公司注册',
          message: '美国/英国/法国/香港/深圳/广州/惠州及各地区公司注册',
          icon: 'icon_5',
          productTypeBid: 1625286645312658,
          list: [],
        },
      ],
      productList: [],
      swipeIndex: 0,
    }
  },
  computed: {
    curProduct() {
      let cur = this.tabList.find(item => item.value == this.activeTab)
      return cur ? cur : {}
    },
  },
  mounted() {
    this.getProduct(this.tabList[0], 0)
  },
  methods: {
    // tab栏切换
    tabChange(val) {
      // 请求过一次的直接取值,不再发请求
      if (this.tabList[val].list.length) {
        this.productList = this.tabList[val].list
        return
      }
      this.getProduct(this.tabList[val], val)
    },

    // 获取产品列表
    getProduct(tabItem, index) {
      const params = {
        productTypeBid: tabItem.productTypeBid,
        pageSize: 8,
        pageNum: 1,
      }
      this.$get('/product/management/getList', params).then((res) => {
        if (res.code === 200) {
          // 给商标列表数据添加二字码对应的颜色
          if (index === 0) {
            res.data.rows.forEach((item) => {
              tradmarkCountryList.forEach((element) => {
                if (item.nation === element.label) {
                  item.color = element.color;
                }
              });
            });
          }
          // 处理数据为方便轮播展示的数据结构
          let productList = []
          for (let i = 0; i < 4; i++) {
            productList.push(res.data.rows.splice(0, 2))
          }
          this.productList = productList
          tabItem.list = productList
        }
      });
    },

    swipeChange(val) {
      this.swipeIndex = val
    },

    // 在线咨询
    toCustomer() {
      /*  window.open(
         "https://chat10.live800.com/live800/chatClient/chatbox.jsp?companyID=1492604&configID=238735&jid=7750908464&s=1",
         // "newwindow",
         // "height=600, width=380, top=200, left=600, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
       ); */
      window.open('https://szssmhzscqyxgs.qiyukf.com/client?k=abfdee37cf8e091ac97958409bea5c19&wp=1&robotShuntSwitch=0&sid=6227279',)
    },

    // 跳转微信小程序
    jumpToWechat() {
      let params = { path: 'pages/serviceMall/serviceMall' }
      if (this.activeTab === 0) {
        params.path = 'pages/trademarkMall/trademarkMall'
      }
      this.toWechat(params)
    },


  },
}
</script>

<style lang="scss" scoped>
.product_content {
  background: url("../../../assets/images/product-info/bg.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  padding: 0.2rem 0;
}
.title_box {
  // background: url('../../../assets/images/product-info/icon_1.png') no-repeat right center;
  // width: 100%;
  // height: 100%;
  // background-size: 100% 100%;
  padding: 0.16rem 0 0.16rem 0.3rem;
  display: flex;
  justify-content: space-between;
}
.title_text {
  font-size: 0.36rem;
  font-weight: bold;
}
.title_msg {
  color: #757679;
  margin-top: 0.08rem;
  font-size: 0.26rem;
}
.bg_img {
  width: 1.42rem;
  min-width: 1.42rem;
  margin-right: 0.6rem;
}
.product_box {
  background-color: white;
  margin: 0 0.3rem;
}

.more_title {
  font-size: 0.3rem;
  display: flex;
  font-weight: bold;
  color: #4e5d9a;
  height: 0.4rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.02rem solid #4e5d9a;
  padding: 0.3rem 0.16rem;
}
.swipe_container {
  margin-top: 0.16rem;
}
.swipe_item {
  display: flex;
  justify-content: space-between;
}
.opt_btns {
  text-align: center;
  margin-top: 0.3rem;
  padding-bottom: 0.32rem;
}
.opt_btns button {
  width: 2.16rem;
  height: 0.7rem;
  font-size: 0.28rem;
}
.opt_btns button + button {
  margin-left: 0.16rem;
}
.indicator_dots_box {
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
}
.indicator_dots_item {
  width: 0.4rem;
  height: 0.06rem;
  border-radius: 0.03rem;
  background-color: #d9d9d9;
}
.indicator_dots_item + .indicator_dots_item {
  margin-left: 0.08rem;
}
.indicator_dots_item.active_dots {
  background-color: var(--them_color);
}
</style>
