import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/styles/index.scss' // global css

import {
  get,
  getWithToken,
  post,
  postWithToken,
  postForm,
  postFormWithToken,
  ossDownload,
  getDownload,
  postDownload,
  putWithToken,
} from './utils/httpReq/httpClient'
Vue.prototype.$get = get
Vue.prototype.$getWithToken = getWithToken
Vue.prototype.$post = post
Vue.prototype.$postWithToken = postWithToken
Vue.prototype.$postForm = postForm
Vue.prototype.$postFormWithToken = postFormWithToken
Vue.prototype.$ossDownload = ossDownload
Vue.prototype.$postDownload = postDownload
Vue.prototype.$getDownload = getDownload
Vue.prototype.$putWithToken = putWithToken

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
