<template>
  <div @click="toWechat" class="more_trademark">
    <img src="@/assets/images/more_trademark.png" alt="" class="img" />
  </div>
</template>

<script>
import { jumpWechat } from '@/mixins/jumpWechat.js'
export default {
  name: 'MoreTrademark',
  mixins: [ jumpWechat ],
  props: {
    centerBannerList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      
    }
  },
  computed: {},
  created() {
    
  },
  methods: {
   
  },
}
</script>

<style lang="scss" scoped>
.more_trademark {
  margin: 0.2rem 0 0.4rem 0;
  width: 100%;
  cursor: pointer;
}
.img {
  width: 100%;
}
</style>
