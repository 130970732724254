import { postForm } from '@/utils/httpReq/httpClient.js'
export const jumpWechat = {
  methods: {
    getUrlSchema(params) {
      if(!(params && params.path)) {
        // 默认跳转页面为首页
        params = { path: 'pages/index/index' }
      }
      return new Promise((resolve, reject)=> {
        postForm('/getWxUrl', params).then(res => {
          if(res && res.code == 200) {
            let url = res?.data
            if(url && (url.includes('https:') || url.includes('weixin:'))) {
              resolve(url)
            } else {
              resolve(false)
            }
          } else {
            resolve(false)
          }
        })
      })
    },

    async toWechat(params) {
      let url = await this.getUrlSchema(params)
      if(!url) return
      location.href = url
    },
  }
}