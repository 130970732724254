<template>
  <div class="footer">
    <!-- <van-collapse v-model="activeNames" class="collapse_box">
      <van-collapse-item title="神马汇服务" name="1">
        <div @click="toWechat">
          <div class="collapse_item">VAT服务</div>
          <div class="collapse_item">EPR服务</div>
          <div class="collapse_item">商标专利服务</div>
        </div>
      </van-collapse-item>
      <van-collapse-item title="联系地址" name="2">
        <div @click="toWechat">
          <div class="collapse_item">深圳总部：深圳市龙华新区东环一路100号良基大厦432-435</div>
          <div class="collapse_item">广州分部：广州市白云区永平街东平北路126号A202</div>
          <div class="collapse_item">惠州分部：惠州市江北文明一路三号中信城市时代1单元9层05号</div>
          <div class="collapse_item">义乌分部：浙江省义乌市鹿港电商小镇8栋311室</div>
        </div>
      </van-collapse-item>
    </van-collapse> -->
    <div class="address_content">
      <p>联系固话：0755-21008336</p>
      <p>深圳总部：深圳市龙华新区东环一路100号良基大厦432-435</p>
      <p>广州分部：广州市白云区永平街东平北路126号A202</p>
      <p>惠州分部：惠州市江北文明一路三号中信城市时代2单元1202A</p>
      <p>义乌分部：浙江省义乌市鹿港电商小镇8栋311室</p>
    </div>
    <div class="code_box">
      <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" class="code_link">©2021 深圳市神马汇知识产权有限公司 粤ICP备16004107号</a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Collapse, CollapseItem, } from 'vant';

Vue.use(Collapse);
Vue.use(CollapseItem);

export default {
  name: 'Footer',
  components: {},
  data() {
    return {
      activeNames: [],
    }
  },
  computed: {},
  created() {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: black;
  overflow: hidden;
}
.collapse_item {
  font-size: 0.26rem;
  padding: 0.16rem 0;
}
.code_box {
  text-align: center;
  padding: 0 0.3rem;
  margin: 0.3rem 0;
}
.code_link {
  color: #3A3A3B;
  font-size: 0.22rem;
}
.address_content {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.22rem;
  padding: 0 0.3rem;
  border-bottom: 0.01rem solid;
}
::v-deep .van-cell {
  background-color: black;
}
::v-deep .van-cell__title {
  color: white;
  font-size: 0.3rem;
  height: 0.9rem;
  line-height: 0.9rem;
}
::v-deep .van-cell__right-icon {
  font-size: 0.3rem;
  line-height: 0.9rem;
}
::v-deep .van-collapse-item__content {
  background-color: black;
}
::v-deep.van-collapse-item--border::after {
  left: 0;
  right: 0;
}

::v-deep.collapse_box .van-collapse-item__title::after {
  left: 0;
  right: 0;
}
</style>
