// 跳转微信小程序URL Scheme--已弃用
export const jumpWeChatUrl = 'weixin://dl/business/?t=H4OwRWA1DNf'

export const tradmarkCountryList = [
  { countryLabel: '全部', label: '', color: '#4E5D9A' },
  { countryLabel: '美国', label: 'US', color: '#4E5D9A' },
  { countryLabel: '欧盟', label: 'EU', color: '#4E5D9A' },
  { countryLabel: '日本', label: 'JP', color: '#FF6239' },
  { countryLabel: '韩国', label: 'KR', color: '#FFA29C' },
  { countryLabel: '中国', label: 'CN', color: '#DF2111' }
]

