<template>
  <div class="item_content">
    <div v-if="isTrademark" class="trademark_title">
      <span class="trademark_dot" :style="{ background: row.color }"></span>
      <span class="trademark_nation" :style="{ color: row.color }"> {{ row.nation | getCountry(countryList) }}商标</span>
    </div>
    <div class="item_title ellipsis1">{{row.productName}}</div>
    <div class="item_decs ellipsis2" :class="isTrademark ? 'height_1' : 'height_2'" v-text="getHtmlText(row.productLabel)"></div>
    <div class="item_price">
      <!-- 秒杀商品 -->
      <div v-if="row.productSKU[0].seckillPrice">
        <span>秒杀价￥</span>
        <span class="prict_num">{{ row.productSKU[0].seckillPrice }}</span>
      </div>
      <!-- 普通商品 -->
      <div v-else>
        <span>￥</span>
        <span class="prict_num">{{row.productSKU[0].salePrice}}</span>
        <span v-if="isYear">/年</span>
      </div>
    </div>
  </div>
</template>

<script>
import { tradmarkCountryList } from '@/const/index.js'
export default {
  name: 'ProductIitem',
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      countryList: Object.freeze(tradmarkCountryList),
    }
  },
  computed: {
    isTrademark() {
      return this.row.trademarkCode ? true : false
    },
    isYear() {
      return this.row.ticketPeriod !== 4 ? true : false
    },
  },
  methods: {
    // 获取HTML里的文字,剔除标签
    getHtmlText(html) {
      return html.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "");
    }

  },
  filters: {
    getCountry(label, countryList) {
      const item = countryList.find((item) => item.label === label);
      if (item) {
        return item.countryLabel;
      } else {
        return label;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item_content {
  border: 0.01rem solid #d9d9d9;
  padding: 0.2rem;
  width: 3rem;
  margin: 0 0.12rem;
}
.item_title {
  font-weight: bold;
  margin-bottom: 0.18rem;
  font-size: 0.3rem;
}
.item_decs {
  color: #757679;
  font-size: 0.24rem;
  height: 0.64rem;
}
.height_1 {
  height: 0.34rem;
}
.height_2 {
  height: 0.62rem;
}
.item_price {
  color: #df2111;
  font-size: 0.24rem;
  margin-top: 0.32rem;
}
.prict_num {
  font-size: 0.5rem;
  font-weight: bold;
}
.trademark_title {
  display: flex;
  font-size: 0.24rem;
  margin-bottom: 0.16rem;
}
.trademark_dot {
  display: block;
  width: 0.03rem;
  height: 0.2rem;
}
.trademark_nation {
  display: block;
  margin-left: 0.1rem;
  height: 0.2rem;
  line-height: 0.2rem;
  font-size: 0.24rem;
  font-weight: bold;
}
</style>
